import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalPrice: 0,
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // addItem(state, action) {
    //   const findItem = state.items.find((obj) => obj.id === action.payload.id);
    //   if (findItem) {
    //     findItem.count++;
    //   } else {
    //     state.items.push({
    //       ...action.payload,
    //       count: 1,
    //     });
    //   }
    //   state.totalPrice = state.items.reduce((sum, obj) => {
    //     return obj.price * obj.count + sum;
    //   }, 0);
    // },

    addItem(state, action) {
      const findItem = state.items.find((obj) => {
        return (
          obj.id === action.payload.id &&
          obj.type === action.payload.type &&
          obj.size === action.payload.size
        );
      });
      if (findItem) {
        findItem.count++;
      } else {
        state.items.push({
          ...action.payload,
          count: 1,
        });
      }
      state.totalPrice = state.items.reduce((sum, obj) => {
        return obj.price * obj.count + sum;
      }, 0);
    },

    // minusItem(state, action) {
    //   const findItem = state.items.find((obj) => {
    //     return (
    //       obj.id === action.payload.id &&
    //       obj.type === action.payload.type &&
    //       obj.size === action.payload.size
    //     );
    //   });
    //   findItem && findItem.count--;

    //   state.totalPrice -= findItem.price;
    // },

    minusItem(state, action) {
      const findItem = state.items.find((obj) => {
        return (
          obj.id === action.payload.id &&
          obj.type === action.payload.type &&
          obj.size === action.payload.size
        );
      });

      if (findItem) {
        findItem.count--;
        state.totalPrice -= findItem.price;
      }
    },

    // removeItem(state, action) {
    //   state.items = state.items.filter((obj) => obj.id !== action.payload);
    // },

    // removeItem(state, action) {
    //   const findItem = state.items.find((obj) => {
    //     return (
    //       obj.id === action.payload.id &&
    //       obj.type === action.payload.type &&
    //       obj.size === action.payload.size
    //     );
    //   });

    //   state.totalPrice -= findItem.price * findItem.count;

    //   state.items = state.items.filter((obj) => {
    //     return obj.id !== action.payload;
    //   });
    // },

    removeItem(state, action) {
      const findItem = state.items.find((obj) => {
        return (
          obj.id === action.payload.id &&
          obj.type === action.payload.type &&
          obj.size === action.payload.size
        );
      });

      if (findItem) {
        state.totalPrice -= findItem.price * findItem.count;

        // Фильтруем элементы, оставляя только те, которые не совпадают с удаляемым
        state.items = state.items.filter((obj) => {
          return !(
            obj.id === action.payload.id &&
            obj.type === action.payload.type &&
            obj.size === action.payload.size
          );
        });
      }
    },

    clearItem(state) {
      state.items = [];
      state.totalPrice = 0;
    },
  },
});

export const { addItem, removeItem, clearItem, minusItem } = cartSlice.actions;

export default cartSlice.reducer;
