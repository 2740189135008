import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = (props) => (
  <ContentLoader
    className="pizza-block"
    speed={2}
    width={280}
    height={465}
    viewBox="0 0 280 465"
    backgroundColor="#d6c2c2"
    foregroundColor="#c9b6b6"
  >
    <rect x="0" y="329" rx="8" ry="8" width="280" height="86" />
    <rect x="0" y="428" rx="8" ry="8" width="90" height="30" />
    <rect x="131" y="429" rx="20" ry="20" width="150" height="30" />
    <circle cx="138" cy="132" r="123" />
    <rect x="0" y="268" rx="8" ry="8" width="280" height="51" />
  </ContentLoader>
);

export default Skeleton;
