import React from "react";
import { Routes, Route } from "react-router-dom";

import "./scss/app.scss";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Cart from "./pages/Cart";
import FullPizza from "./pages/FullPizza";

import MayLoyoat from "./layoats/MayLoyoat";

export const SearchContext = React.createContext();

function App() {
  return (
    <Routes>
      <Route path="/" element={<MayLoyoat />}>
        <Route path="" element={<Home />} />
        <Route path="pizza/:id" element={<FullPizza />} />
        <Route path="cart" element={<Cart />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;

// import React from "react";
// import { Routes, Route } from "react-router-dom";

// import "./scss/app.scss";
// import Header from "./scss/components/Header";
// import Home from "./pages/Home";
// import NotFound from "./pages/NotFound";
// import Cart from "./pages/Cart";

// export const SearchContext = React.createContext();

// function App() {
//   const [searchValue, setSearchValue] = React.useState("");
//   return (
//     <div className="wrapper">
//       <SearchContext.Provider value={{ searchValue, setSearchValue }}>
//         <Header />
//         <div className="content">
//           <div className="container">
//             <Routes>
//               <Route path="/" element={<Home />} />
//               <Route path="/cart" element={<Cart />} />
//               <Route path="*" element={<NotFound />} />
//             </Routes>
//           </div>
//         </div>
//       </SearchContext.Provider>
//     </div>
//   );
// }

// export default App; до перевода на оутлет/ добавление мэйлолта
