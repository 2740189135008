import React from "react";
import NotFoundBlock from "../scss/components/NotFoundBlock/index";

const NotFound = () => {
  return (
    <>
      <NotFoundBlock />
    </>
  );
};

export default NotFound;
