import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import loading from "../assets/img/free-icon-loading-3305803.png";

const FullPizza = () => {
  const [pizza, setPizza] = React.useState();
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchPizza() {
      try {
        const { data } = await axios.get(
          "https://66b35dc07fba54a5b7eca768.mockapi.io/items/" + id
        );
        setPizza(data);
      } catch (error) {
        alert("Пицца не найдена");
        navigate("/");
      }
    }
    fetchPizza();
  }, []);

  if (!pizza) {
    // return "Загрузка...";
    return (
      <div className="loading">
        <img src={loading} alt="" />
      </div>
    );
  }

  return (
    <div>
      <div className="container">
        <div className="pizzaPage">
          <div className="pizzaPage__leftSide">
            <div className="pizzaPage__img">
              <img className="pizzaPage__img" src={pizza.imageUrl} alt="" />
            </div>
          </div>
          <div className="pizzaPage__rightSide">
            <div className="pizzaPage__group">
              <div className="pizzaPage__title">
                <h2>{pizza.title}</h2>
              </div>
              <div className="pizzaPage__desc">{pizza.desc}</div>
              <Link to="/">
                <button className="pizzaPage__back">Вернуться назад</button>
              </Link>
              <div className="pizzaPage__price">{pizza.price} ₽</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPizza;
