import React from "react";

import Header from "../scss/components/Header";
import { Outlet } from "react-router-dom";

import { SearchContext } from "../App";

const MayLoyoat = () => {
  const [searchValue, setSearchValue] = React.useState("");
  return (
    <div className="wrapper">
      <SearchContext.Provider value={{ searchValue, setSearchValue }}>
        <Header />
        <div className="content">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </SearchContext.Provider>
    </div>
  );
};

export default MayLoyoat;
