import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../../redux/slices/cartSlice";
import { Link } from "react-router-dom";

function PizzaBlock({ id, title, price, imageUrl, sizes, types }) {
  // const [activeType, setActiveType] = React.useState(0);
  // const [activeSize, setActiveSize] = React.useState(0); прошлый стейт, где был один счетчик для всего
  const [activeType, setActiveType] = React.useState(null);
  const [activeSize, setActiveSize] = React.useState(null);

  const typeNames = ["тонкое", "традиционное"];

  // Конфигурация дополнительных цен
  const typePriceConfig = {
    0: 0, // Тонкое
    1: 100, // Традиционное
  };

  const sizePriceConfig = {
    26: 0, // Размер 26 см
    30: 50, // Размер 30 см
    40: 100, // Размер 40 см
  };

  // Устанавливаем цены на основе выбранных параметров
  const additionalTypePrice = typePriceConfig[activeType] || 0;
  const additionalSizePrice = sizePriceConfig[sizes[activeSize]] || 0;
  const finalPrice = price + additionalTypePrice + additionalSizePrice;

  // Эффект для установки первого доступного типа и размера
  React.useEffect(() => {
    if (types.length > 0) {
      setActiveType(types[0]); // Устанавливаем первый доступный тип
    }
    if (sizes.length > 0) {
      setActiveSize(0); // Устанавливаем первый доступный размер (по индексу)
    }
  }, [types, sizes]);

  const onClickAdd = () => {
    const item = {
      uniqueId, // Добавляем уникальный идентификатор
      id,
      title,
      price: finalPrice,
      imageUrl,
      type: typeNames[activeType],
      size: sizes[activeSize],
    };
    dispatch(addItem(item));
  };

  const dispatch = useDispatch();
  // const cartItem = useSelector((state) =>
  //   state.cart.items.find((obj) => obj.id === id)
  // );

  const uniqueId = id + "_" + activeType + "_" + sizes[activeSize]; // Создаем уникальный идентификатор для каждой комбинации

  const cartItem = useSelector((state) =>
    state.cart.items.find((obj) => obj.uniqueId === uniqueId)
  );

  const addedCount = cartItem ? cartItem.count : 0;

  return (
    <div className="pizza-block">
      <Link to={`/pizza/${id}`}>
        <img className="pizza-block__image" src={imageUrl} alt="Pizza" />
        <h4 className="pizza-block__title">{title}</h4>
      </Link>
      <div className="pizza-block__selector">
        <ul>
          {types.map((typeId) => (
            <li
              key={typeId}
              onClick={() => setActiveType(typeId)}
              className={activeType === typeId ? "active" : ""}
            >
              {typeNames[typeId]}
            </li>
          ))}
        </ul>
        <ul>
          {sizes.map((size, i) => (
            <li
              key={size}
              onClick={() => setActiveSize(i)}
              className={activeSize === i ? "active" : ""}
            >
              {size} см.
            </li>
          ))}
        </ul>
      </div>
      <div className="pizza-block__bottom">
        <div className="pizza-block__price">от {finalPrice} ₽</div>
        <button
          onClick={onClickAdd}
          // onClick={onClickAdd}
          className="button button--outline button--add"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
              fill="white"
            />
          </svg>
          <span>Добавить</span>
          {addedCount > 0 && <i>{addedCount}</i>}
        </button>
      </div>
    </div>
  );
}

export default PizzaBlock;
