import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPizzas } from "../redux/slices/pizzaSlice";

import { setCategoryId } from "../redux/slices/filterSlice";
import Categories from "../scss/components/Categories";
import Sort from "../scss/components/Sort";
import PizzaBlock from "../scss/components/PizzaBlock";
import Skeleton from "../scss/components/skeleton";
import { SearchContext } from "../App";

export const Home = () => {
  const dispatch = useDispatch();
  // const isSearch = React.useRef(false); ВОЗМОЖНО ПРИГОДИТЬСЯ ДАЛЬШЕ (НАПИСАНО В СЛОМАННОМ УРОВКЕ 15)

  // const categoryId = useSelector((state) => state.filter.categoryId);
  // const sortType = useSelector((state) => state.filter.sort.sortProperty); более разверное описание кода ниже

  const { categoryId, sort } = useSelector((state) => state.filter);
  const { items, status } = useSelector((state) => state.pizza);
  const sortType = sort.sortProperty;

  const { searchValue } = React.useContext(SearchContext);
  // const [items, setItems] = React.useState([]);

  // const [isLoading, setIsLoading] = React.useState(true);
  // const [categoryId, setCategoryId] = React.useState(0);
  // const [sortType, setSortType] = React.useState({
  //   name: "сначала недорогие",
  //   sortProperty: "price&order=desc",
  // }); через юстейт, а не контекст

  const onChangeCategory = (id) => {
    dispatch(setCategoryId(id));
  };

  const getPizzas = async () => {
    // const { data } = await axios.get(
    //   `https://66b35dc07fba54a5b7eca768.mockapi.io/items?${`category=${categoryId}`}&sortBy=${sortType}&order=desc`
    // );
    dispatch(
      fetchPizzas({
        categoryId,
        sortType,
      })
    );

    // fetch(
    //   `https://66b35dc07fba54a5b7eca768.mockapi.io/items?${`category=${categoryId}`}&sortBy=${sortType}&order=desc`
    // )
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((arr) => {
    //     setItems(arr);
    //     setIsLoading(false);
    //   }); тоже самое, но через фетч
  };

  React.useEffect(() => {
    getPizzas();
    window.scrollTo(0, 0);
  }, [categoryId, sortType]);

  const pizzas = items
    .filter((obj) => {
      if (obj.title.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
      return false;
    })
    .map((obj) => (
      <PizzaBlock
        key={obj.id}
        {...obj}
        // title={obj.title}
        // price={obj.price}
        // imageUrl={obj.imageUrl}
        // sizes={obj.sizes}
        // types={obj.types} более разверное описание кода выше
      />
    ));
  const skeletons = [...new Array(8)].map((_, index) => (
    <Skeleton key={index} />
  ));

  return (
    <>
      <div className="content__top">
        <Categories value={categoryId} onChangeCategory={onChangeCategory} />
        <Sort />
      </div>
      <h2 className="content__title">Все пиццы</h2>
      {status === "error" ? (
        <div>
          <h2>
            Видимо, пиццы не поступили 😕, пожалуйста, попробуйте зайти позже
          </h2>
        </div>
      ) : (
        <div className="content__items">
          {/* {status === "loading" ? skeletons : pizzas} */}
          {status === "loading" ? (
            skeletons
          ) : pizzas.length === 0 ? (
            <div className="notFoundFilter">Нет такой 😕</div>
          ) : (
            pizzas
          )}
        </div>
      )}
    </>
  );
};

export default Home;
