import React from "react";

import styles from "./NotFoundBlock.module.scss";

const NotFoundBlock = () => {
  return (
    <div className={styles.notFoundBlock}>
      <h1>
        <span>😕</span> <br /> Ничего не найдено
      </h1>
      <p> К сожалению, такая страница отстутсвует в нашем интернет-магазине</p>
    </div>
  );
};

export default NotFoundBlock;
